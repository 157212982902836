import React, { Component } from 'react';

import logo from '../assets/logo.png';

import Button from './Button';
import CheckYourEmail from './CheckYourEmail';
import Form from './Form';
import { ReCaptcha } from 'react-recaptcha-v3';
import { loadReCaptcha } from 'react-recaptcha-v3';

const API = `${process.env.REACT_APP_API_URL}`;
const DEFAULT_QUERY = 'person';
const CAPTCHA_SITE_KEY = `${process.env.REACT_APP_CAPTCHA_SITE_KEY}`;


class App extends Component {
  state = {
    form_type: '',
    form_visibility: false,
    email: '',
    afterFirstFetch: false,
    token: '',
    key: 0
  }

  checkYourStatus = () => {
    fetch(API + DEFAULT_QUERY + "?email=" + this.state.email + "&token=" + this.state.token)
      .then(() => {
        this.setState({ afterFirstFetch: true });
      });
  }

  setUserEmail = e => {
    this.setState({ email: e.target.value });
  }

  onKeyPressAction = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.checkYourStatus();
    }
  }

  handleClick = prop => {
    this.setState({ form_type: prop, form_visibility: true })
  }

  checkOnceAgain = e => {
    this.setState({ status: [] });
    this.setState({ afterFirstFetch: false });
    let newKey = this.state.key + 1;
    this.setState({key: newKey});
  }

  verifyCallback = (recaptchaToken) => {
    console.log(recaptchaToken, "<= your recaptcha token");
    this.setState({token: recaptchaToken});
  }

  componentDidMount() {
    loadReCaptcha(CAPTCHA_SITE_KEY);
  }

  render() {
    const {
      form_visibility,
      form_type,
      afterFirstFetch,
      email
    } = this.state

    return (
      <div className="container" key={this.state.key}>
        <ReCaptcha
            ref={(el) => {this.captcha = el;}}
            render="explicit"
            sitekey={CAPTCHA_SITE_KEY}
            action='checkHairStatus'
            verifyCallback={this.verifyCallback}
        />
        <header className="header">
          <a href="/">
            <img src={logo} className="logo" alt="logo" />
          </a>
        </header>

        <main className="main">
          {form_visibility !== true && <>
            <p className="title">Dziękujemy za Twoje warkocze!</p>
            <p className="paragraph">
              Aby sprawdzić, czy do nas dotarły wybierz sposób w jaki były przekazane
                  </p>
            <div className="button-container">
              <Button
                title="Wysłane samodzielnie"
                handleClick={() => this.handleClick('person')}
              />
              <Button
                title="Przekazane salonowi"
                handleClick={() => this.handleClick('hairdresser')}
              />
            </div>
          </>}

          {form_visibility === true && afterFirstFetch === false &&
            <Form
              type={form_type}
              setUserEmail={this.setUserEmail}
              onKeyPressAction={this.onKeyPressAction}
              checkYourStatus={this.checkYourStatus}
            />
          }

          {afterFirstFetch === true && email && (
            <div className="thankyou-container">
              <CheckYourEmail email={email} />
              <Button title="Wróć" handleClick={() => this.checkOnceAgain()} />
            </div>
          )}

        </main>

        <footer className="footer">
          <p>2019 Fundacja Rak'n'Roll. Wygraj życie! Wszystkie prawa do materiałów na stronie są zastrzeżone</p>
        </footer>
      </div>
    );
  }
}

export default App;
