import React from 'react'

import Button from './Button'

export default class Form extends React.Component {

  handleSubmit (e) {
    e.preventDefault()
    this.props.checkYourStatus()
  }

  render() {
    const {
      type,
      setUserEmail,
      onKeyPressAction,
    } = this.props;

    return (
      <div className="option-container">

        {type === 'person' && (
          <p className="title">
            Wpisz adres e-mail, który podałaś/łeś w oświadczeniu:
          </p>
        )}

        {type === 'hairdresser' && (<>
          <p className="title">
            Jeśli oddawałeś włosy w ciągu ostatnich dwóch miesięcy, skontaktuj się z salonem, aby upewnić się że warkocze zostały wysłane.
          </p>
          <p className="paragraph">
            Jeśli ten czas minął, wpisz adres e-mail, który podałaś/łeś w oświadczeniu
          </p>
        </>)}

        <form className="form" onSubmit={(e) => this.handleSubmit(e)}>
          <input
            placeholder="e-mail"
            type="email"
            name="email"
            className="input"
            pattern="[^ @]*@[^ @]*"
            onChange={(e) => setUserEmail(e)}
            onKeyPress={(e) => onKeyPressAction(e)}
            required
          />
          <div className='consent-container'>
            <label className='consent-box' htmlFor="consent_01">
              <input type="checkbox" id="consent_01" name="consent_01" required />
              Wyrażam zgodę na przetwarzanie moich ww. danych osobowych, w celu uzyskania informacji o statusie nadanej przez mnie przesyłki za pośrednictwem poczty elektronicznej przez Fundację Rak'n'Roll. Wygraj życie! z siedzibą w Warszawie przy al. Wilanowskiej 313A, 02-665 Warszawa; KRS: 0000338803, NIP: 951229699.
                <br /><br />
              Jednocześnie, informujemy, że Państwa zgoda może zostać cofnięta w dowolnym momencie przez wysłanie wiadomości e-mail na adres naszego Administratora adres e-mail biuro@raknroll.pl , Inspektora Danych adres e-mail agata@raknroll.pl lub wysłanie korespondencji na adres siedziby Fundacji. Informujemy, że nie jesteście Państwo profilowani. Państwa dane nie będą przekazywane do państwa trzeciego lub organizacji międzynarodowej.
            </label>

            <label className='consent-box' htmlFor="consent_02">
              <input type="checkbox" id="consent_02" name="consent_02" required />
              Oświadczam, że zapoznałam/em się z <a href='/Klauzula_informacyjna_Rak_n_Roll.pdf' target='_blank'>klauzulą informacyjną</a>.
            </label>
          </div>
          <Button title="Wyślij" />
        </form>
      </div>
    );
  }
}