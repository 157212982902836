import React from 'react';

const CheckYourEmail = (props) => {
  return (
    <div>
      <p className="title">Sprawdź swoją skrzynkę e-mail</p>
      <p className="paragraph">{props.email}</p>
    </div>
  );
}

export default CheckYourEmail;
